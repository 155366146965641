export const siteData = {
  "name": "OBRIZL",
  "address": "1762 ROUTE DE PONTENX,",
  "postCode": "40160",
  "city": "parentis",
  "phone": "0310286961",
  "url": "https://obrizl.store",
  "urlSub": "obrizl.store",
  "price": "price_1Q557oFDhlrD12bS03X6kIaO",
  "pk": "pk_live_51Q553BFDhlrD12bSQB0i8WG3DYJ5Vj8R5aPdHWgmPmX1mSXcheko5Z3chiXD7pUHEl1CS3qJq7Hgr3AAd7bsCsV300H7fbqiXr",
  "sk": "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"
};